import React, { useEffect, useState } from "react";
import img1 from "./assets/blog images 1.svg";
import "./Blog.css";
import { MdOutlineArrowForward } from "react-icons/md";
import Nav from "../NavBar/Nav";
import Footer from "../Footer/Footer";

const BlogsList = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 230) {
      console.log("window.scrollY", window.scrollY);
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="blogs-container">
        <div className={`blogs-nav ${isScrolled ? "blogs-nav-scrolled" : ""} `}>
          <Nav />
        </div>

        <div className="blogs-hero-container">
          <div className="blogs-dev-wrapper">
            <div className="blogs-app-hero-headings">
              <div>
                <p>BLOGS</p>
                <span>Learn & know more Odoo ERP software</span>
                <button>Let’s Connect</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blogs-list-container">
        <div className="blogs-list-blog">
          <img src={img1} alt="clogimg" />
          <div className="blogs-list-blog-detail">
            <p>How Odoo Streamlines Business Operations?</p>
            <span>
              In today's fast-paced business environment, organizations are
              constantly seeking ways to improve efficiency and streamline
              operations
            </span>
            <button>
              Read More <MdOutlineArrowForward />
            </button>
          </div>
        </div>

        <div className="blogs-list-blog">
          <img src={img1} alt="clogimg" />
          <div className="blogs-list-blog-detail">
            <p>How Odoo Streamlines Business Operations?</p>
            <span>
              In today's fast-paced business environment, organizations are
              constantly seeking ways to improve efficiency and streamline
              operations
            </span>
            <button>
              Read More <MdOutlineArrowForward />
            </button>
          </div>
        </div>

        <div className="blogs-list-blog">
          <img src={img1} alt="clogimg" />
          <div className="blogs-list-blog-detail">
            <p>How Odoo Streamlines Business Operations?</p>
            <span>
              In today's fast-paced business environment, organizations are
              constantly seeking ways to improve efficiency and streamline
              operations
            </span>
            <button>
              Read More <MdOutlineArrowForward />
            </button>
          </div>
        </div>

        <div className="blogs-list-blog">
          <img src={img1} alt="clogimg" />
          <div className="blogs-list-blog-detail">
            <p>How Odoo Streamlines Business Operations?</p>
            <span>
              In today's fast-paced business environment, organizations are
              constantly seeking ways to improve efficiency and streamline
              operations
            </span>
            <button>
              Read More <MdOutlineArrowForward />
            </button>
          </div>
        </div>

        <div className="blogs-list-blog">
          <img src={img1} alt="clogimg" />
          <div className="blogs-list-blog-detail">
            <p>How Odoo Streamlines Business Operations?</p>
            <span>
              In today's fast-paced business environment, organizations are
              constantly seeking ways to improve efficiency and streamline
              operations
            </span>
            <button>
              Read More <MdOutlineArrowForward />
            </button>
          </div>
        </div>
      </div>

      <div className="inside-footer">
        <Footer />
      </div>
    </div>
  );
};

export default BlogsList;
