import React, { useEffect, useState } from "react";
import Nav from "../NavBar/Nav";
import { GoDotFill } from "react-icons/go";
import publicImg from "./aboutAssets/about1010-1.svg";
import publicImg1 from "./aboutAssets/about1010-5.svg";
import publicImg2 from "./aboutAssets/about1010-2.svg";
import publicImg3 from "./aboutAssets/about1010-3.svg";
import publicImg4 from "./aboutAssets/about1010-4.svg";
import publicImg5 from "./aboutAssets/1_6.png";
import icon1 from "./aboutAssets/review-icons/ic-quotes.svg";
import recognizeImg5 from "../../assets/Logo-Strategic-Partnerships-2022_1-2-2 1.png";
import recognizeImg2 from "../../assets/clutch-top-info-tech-white 1.png";
import recognizeImg3 from "../../assets/Clip path group.png";
import recognizeImg4 from "../../assets/ENG-Inc.-5000 1.png";
import recognizeImg1 from "../../assets/ISO.png";
import Footer from "../Footer/Footer";

import "./About.css";
import ContactUs from "../ContactUs/ContactUs";

const About = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [technologyTabs, setTechnologyTabs] = useState("Odoo Solutions");
  const [isSliding, setIsSliding] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 230) {
      console.log("window.scrollY", window.scrollY);
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleTechnologyTabClick = (tab) => {
    setIsSliding(false); // Reset the sliding state before changing the tab
    setTimeout(() => {
      setTechnologyTabs(tab);
      setIsSliding(true); // Trigger the sliding animation after tab change
    }, 50); // Small delay to allow reset before triggering slide
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="about-two-container">
        <div
          className={`HireDevelopers-nav ${
            isScrolled ? "HireDevelopers-nav-scrolled" : ""
          } `}
        >
          <Nav />
        </div>

        <div className="about-two-hero-container">
          <p>
            Welcome to Legions Soft
            <br />
            your trusted partner in driving
            <br />
            digital transformation
          </p>
          <img src={publicImg} alt="img" />
        </div>
      </div>

      <div className="about-two-part-1-container">
        <p>Who are we?</p>
        <span>
          We are a team of experts providing cutting-edge solutions in App
          Development, Odoo ERP services, Web Development, and Digital
          Marketing. Our mission is to empower businesses with scalable,
          innovative, and fully customized solutions that fuel growth,
          streamline operations, and enhance productivity.
        </span>
        <div className="about-two-part-1-container-section-1">
          <div>
            <p>Our Vision</p>
            <span>
              Our vision is to be at the forefront of the digital revolution,
              helping businesses of all sizes leverage the power of technology
              to stay competitive in a rapidly changing world. We aim to shape
              the future by developing solutions that not only solve today’s
              problems but anticipate tomorrow’s challenges.
            </span>
          </div>
          <img src={publicImg1} alt="img" />
        </div>
        <div className="about-two-part-1-container-section-2">
          <img src={publicImg2} alt="img" />
          <div>
            <p>What Makes Us Different?</p>
            <h5>
              At Legions Soft, we focus on three core principles that set us
              apart:
            </h5>
            <ul>
              <li>
                Innovation-Driven Approach: We believe in continuous learning
                and staying ahead of technological trends. From AI integrations
                to the latest app development frameworks, we constantly push the
                boundaries of what's possible.
              </li>
              <li>
                Customer-Centric Focus: Your goals are our goals. We listen
                closely to your needs, ensuring our solutions are perfectly
                aligned with your business objectives. Every project is a
                collaboration that centers around your vision.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="about-two-part-2-container">
        <p>Our history</p>
        <div>
          <div>
            <p>2018</p>
            <span>
              With passion and a dream, we set out to redefine digital solutions
            </span>
          </div>
          <div>
            <p>2019</p>
            <span>
              Our dedication paid off as we brought our first apps and websites
              to life.
            </span>
          </div>
          <div>
            <p>2020</p>
            <span>
              In a year of challenges, we adapted and grew—introducing Odoo
              services to help businesses thrive
            </span>
          </div>
          <div>
            <p>2021</p>
            <span>
              Fueled by our clients' trust, we delivered innovative apps,
              websites, and Odoo solutions with heart.
            </span>
          </div>
        </div>
      </div>

      <div className="about-two-part-3-container">
        <p>Our strengths</p>
        <div className="about-two-part-3-container-section-1">
          <div className="about-two-part-3-container-section-1-part-1">
            <button
              className={
                technologyTabs === "Odoo Solutions"
                  ? "about-two-secondary-tabs-active"
                  : ""
              }
              onClick={() => handleTechnologyTabClick("Odoo Solutions")}
            >
              Odoo Solutions
            </button>
            <button
              className={
                technologyTabs === "App development"
                  ? "about-two-secondary-tabs-active"
                  : ""
              }
              onClick={() => handleTechnologyTabClick("App development")}
            >
              App development
            </button>
            <button
              className={
                technologyTabs === "Web development"
                  ? "about-two-secondary-tabs-active"
                  : ""
              }
              onClick={() => handleTechnologyTabClick("Web development")}
            >
              Web development
            </button>
          </div>

          {technologyTabs === "Odoo Solutions" && (
            <div
              className={`about-two-part-3-container-section-2 ${
                isSliding ? "slide-in" : ""
              }`}
            >
              <p>Odoo Solutions</p>
              <span>
                At Legions Soft, we offer a comprehensive suite of services
                designed to meet diverse business needs. Our Odoo solutions
                include implementation, customization, and support tailored to
                various industries like manufacturing and education.
              </span>
            </div>
          )}
          {technologyTabs === "App development" && (
            <div
              className={`about-two-part-3-container-section-2 ${
                isSliding ? "slide-in" : ""
              }`}
            >
              <p>App development</p>
              <span>
                In app development, we create seamless mobile experiences across
                iOS and Android platforms, as well as hybrid solutions. We focus
                on user-centric designs that not only look great but deliver
                exceptional performance.
              </span>
            </div>
          )}
          {technologyTabs === "Web development" && (
            <div
              className={`about-two-part-3-container-section-2 ${
                isSliding ? "slide-in" : ""
              }`}
            >
              <p>Web development</p>
              <span>
                Our web development services encompass everything from custom
                websites to blockchain applications. We build scalable,
                high-performance platforms that are both visually stunning and
                functionally robust.
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="about-two-part-4-container">
        <p>Thousands of satisfied clients</p>
        <span>
          Don’t just take our word for it - thousands of clients are happy by
          partnering with us
        </span>
        <div className="about-two-part-4-container-section-1">
          <div className="about-two-part-4-container-section-1-part-1">
            <div className="about-two-part-4-container-section-1-part-1-1">
              <p>Sarah L.,</p>
              <span>CEO of Tech Innovations</span>
            </div>
            <div className="about-two-part-4-container-section-1-part-1-2">
              <img src={icon1} alt="icon" />
              <p>
                Working with Legions Soft was a game-changer for our business.
                Their team took our vision for a mobile app and transformed it
                into a seamless, user-friendly experience that exceeded our
                expectations. The app’s performance across both iOS and Android
                is flawless, and our users love it! Highly recommended!"
              </p>
            </div>
          </div>
          <div className="about-two-part-4-container-section-1-part-1">
            <div className="about-two-part-4-container-section-1-part-1-1">
              <p>David R</p>
              <span>Founder of Fintech Solutions</span>
            </div>
            <div className="about-two-part-4-container-section-1-part-1-2">
              <img src={icon1} alt="icon" />
              <p>
                "Legions Soft delivered an incredible custom website for our
                company. Their attention to detail and understanding of our
                needs resulted in a platform that not only looks great but also
                drives engagement. The level of expertise they brought to our
                project, especially with the integration of blockchain
                technology, was truly impressive."
              </p>
            </div>
          </div>
          <div className="about-two-part-4-container-section-1-part-1">
            <div className="about-two-part-4-container-section-1-part-1-1">
              <p>Jessica M</p>
              <span>Operations Manager at Growtoro</span>
            </div>
            <div className="about-two-part-4-container-section-1-part-1-2">
              <img src={icon1} alt="icon" />
              <p>
                "We partnered with Legions Soft for our Odoo implementation, and
                it was one of the best decisions we've made. They handled
                everything from customization to seamless integrations with our
                existing systems. Their knowledge of Odoo helped us streamline
                operations, saving time and reducing costs. An exceptional team
                to work with!"
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-two-part-5-container">
        <div className="about-two-part-5-container-section-1">
          <img src={publicImg3} alt="img" />
          <div className="about-two-part-5-container-section-1-part-1">
            <div>
              <span>Our Process: Technology Meets Strategy</span>
              <p>
                We pride ourselves on combining technical expertise with
                strategic thinking. Every project follows a carefully curated
                process that ensures optimal outcomes
              </p>
            </div>
            <ul>
              <li>
                <p>
                  <span>1. Discovery Phase:</span>
                  We dive deep into your business, analyzing your needs, pain
                  points, and objectives to create a personalized strategy.
                </p>
              </li>
              <li>
                <p>
                  <span>2. Technical Blueprint:</span>
                  Our developers design a technical architecture tailored to
                  your solution, ensuring scalability and efficiency
                </p>
              </li>
              <li>
                <p>
                  <span>3. Execution:</span>
                  We turn ideas into reality through agile development
                  methodologies, ensuring flexibility and speed while
                  maintaining the highest quality standards.
                </p>
              </li>
              <li>
                <p>
                  <span>4. Continuous Improvement:</span>
                  Post-launch, we offer full support and enhancement services to
                  keep your digital solutions updated with the latest
                  advancements.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="about-two-part-5-container-section-1">
          <div className="about-two-part-5-container-section-1-part-1">
            <div>
              <span>What We Stand For</span>
              <p>
                At Legions Soft, we are driven by values that guide every
                project:
              </p>
            </div>
            <ul>
              <li>
                <p>
                  <span>1. Transparency:</span>
                  We believe in open communication and complete transparency,
                  ensuring that our clients are always in the loop.
                </p>
              </li>
              <li>
                <p>
                  <span>2. Quality:</span>
                  Every line of code we write, every strategy we devise, is held
                  to the highest standards of quality and excellence.
                </p>
              </li>
              <li>
                <p>
                  <span>3. Innovation:</span>
                  We continuously explore new technologies and methods to keep
                  our clients at the cutting edge of digital innovation.
                </p>
              </li>
            </ul>
          </div>
          <img src={publicImg4} alt="img" />
        </div>
      </div>

      <div className="home-recognize-container">
        <p>Recognized among the best, by the best</p>
        <div>
          <img src={recognizeImg1} alt="abc" />
          <img src={recognizeImg2} alt="abc" />
          <img src={recognizeImg3} alt="abc" />
          <img src={recognizeImg4} alt="abc" />
          <img src={recognizeImg5} alt="abc" />
        </div>
      </div>

      <ContactUs />

      <div className="inside-footer">
        <Footer />
      </div>
    </div>
  );
};

export default About;
