import React from "react";
import NavScrolled from "../../NavBar/NavScrolled";
import img1 from "./assets/1.png";
import img2 from "./assets/1_2.png";
import img3 from "./assets/1_3.png";
import img5 from "./assets/1_5.png";
import img6 from "./assets/1_6.png";
import img7 from "./assets/1_7.png";
import img8 from "./assets/1_8.png";
import img9 from "./assets/1_9.png";
import img10 from "./assets/1_10.png";
import "./WebHosting.css";
import { TiTick } from "react-icons/ti";
import icon1 from "./assets/icons/ic-wordpress.svg";
import icon2 from "./assets/icons/google-dark.svg";
import icon3 from "./assets/icons/hostadvice-dark.svg";
import icon4 from "./assets/icons/wpbeginner-dark.svg";
import icon5 from "./assets/icons/ic-quotes-filled.svg";
import { IoStar } from "react-icons/io5";
import ContactUs from "../../ContactUs/ContactUs";
import Footer from "../../Footer/Footer";

const WebHosting = () => {
  return (
    <div>
      <NavScrolled />

      <div className="webhosting-part-1">
        <div className="webhosting-part-1-section">
          <span>Up to 75% off Web hosting</span>
          <p>Secure. Speedy. The way your website should be</p>
          <div>
            <p>
              <TiTick size={20} /> 24/7 customer support
            </p>
            <p>
              <TiTick size={20} /> 24/7 Free domain
            </p>
            <p>
              <TiTick size={20} /> 24/7 Free website migration
            </p>
          </div>
        </div>
        <img src={img1} alt="img" />
      </div>

      <div className="webhosting-part-2">
        <div className="webhosting-part-2-section-1">
          <img src={icon1} alt="icon" />
          <p>Recommended by</p>
          <span>WordPress.org</span>
        </div>
        <div className="webhosting-part-2-section-2">
          <div className="webhosting-part-2-section-2-part-1">
            <img src={icon2} alt="icon" />
            <div>
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
            </div>
            <p>
              Rating: <span>4.8/5 | 1,237</span> reviews
            </p>
          </div>
          <div className="webhosting-part-2-section-2-part-1">
            <img src={icon3} alt="icon" />
            <div>
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
            </div>
            <p>
              Rating: <span>4.8/5 | 1,237</span> reviews
            </p>
          </div>
          <div className="webhosting-part-2-section-2-part-1">
            <img src={icon4} alt="icon" />
            <div>
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
              <IoStar size={26} />
            </div>
            <p>
              Rating: <span>4.8/5 | 1,237</span> reviews
            </p>
          </div>
        </div>
      </div>

      <div className="webhosting-part-3">
        <img src={img2} alt="img" />
        <div>
          <div className="webhosting-part-3-section-1">
            <img src={icon5} alt="icon" />
            <p>
              We honestly reference Hostinger as the benchmark for our engineers
              when providing support.
            </p>
          </div>
          <div className="webhosting-part-3-section-2">
            <p>Charlie Low & Dale Comley</p>
            <span>Co-founders of Nohma | nohma.com</span>
          </div>
        </div>
      </div>

      <div className="webhosting-part-4">
        <div className="webhosting-part-4-section-1">
          <p>Round-the-clock support for your success</p>
          <div>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
          </div>
        </div>
        <img src={img5} alt="img" />
      </div>

      <div className="webhosting-part-5">
        <div className="webhosting-part-5-section-1">
          <p>
            <IoStar size={28} /> Trustpilot
          </p>
          <span>
            They are the best when it comes to ease of use and definitely the
            best immediate support you receive compared to others.
          </span>
          <div>
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
          </div>
        </div>
        <div className="webhosting-part-5-section-1">
          <p>
            <IoStar size={28} /> Trustpilot
          </p>
          <span>
            They are the best when it comes to ease of use and definitely the
            best immediate support you receive compared to others.
          </span>
          <div>
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
          </div>
        </div>
        <div className="webhosting-part-5-section-1">
          <p>
            <IoStar size={28} /> Trustpilot
          </p>
          <span>
            They are the best when it comes to ease of use and definitely the
            best immediate support you receive compared to others.
          </span>
          <div>
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
          </div>
        </div>
      </div>

      <div className="webhosting-part-6">
        <img src={img6} alt="img" />
        <div className="webhosting-part-6-section-1">
          <p>Effortless website launch powered by AI</p>
          <div>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
          </div>
        </div>
      </div>

      <div className="webhosting-part-7">
        <p>Powerful Managed Hosting Solutions in Pakistan</p>
        <button>Get Started</button>
        <span>
          <TiTick size={25} />
          30-day money-back guarantee
        </span>
      </div>

      <div className="webhosting-part-4">
        <div className="webhosting-part-4-section-1">
          <p>Secure hosting solutions</p>
          <div>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
          </div>
        </div>
        <img src={img7} alt="img" />
      </div>

      <div className="webhosting-part-6">
        <img src={img8} alt="img" />
        <div className="webhosting-part-6-section-1">
          <p>Effortless website launch powered by AI</p>
          <div>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
          </div>
        </div>
      </div>
      <div className="webhosting-part-8">
        <div>
          <p>Techradar</p>
          <span>
            Hostinger is our number one pick for several reasons. When testing
            its service compared to others, our site performed the best while
            being hosted on Hostinger.
          </span>
        </div>
        <div>
          <p>Techradar</p>
          <span>
            Hostinger is our number one pick for several reasons. When testing
            its service compared to others, our site performed the best while
            being hosted on Hostinger.
          </span>
        </div>
        <div>
          <p>Techradar</p>
          <span>
            Hostinger is our number one pick for several reasons. When testing
            its service compared to others, our site performed the best while
            being hosted on Hostinger.
          </span>
        </div>
      </div>
      <div className="webhosting-part-7">
        <p>Host Websites With Top-Quality Performance</p>
        <button>See pricing</button>
        <span>
          <TiTick size={25} />
          30-day money-back guarantee
        </span>
      </div>
      <div className="webhosting-part-4">
        <div className="webhosting-part-4-section-1">
          <p>Web host that elevates your business</p>
          <div>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
          </div>
        </div>
        <img src={img9} alt="img" />
      </div>
      <div className="webhosting-part-4">
        <img src={img10} alt="img" />
        <div className="webhosting-part-4-section-1">
          <p>Website migration made easy</p>
          <div>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
            <p>
              <TiTick size={25} /> Access expert help via live chat or email
              anytime you need it.
            </p>
          </div>
        </div>
      </div>
      <div className="webhosting-part-5">
        <div className="webhosting-part-5-section-1">
          <p>
            <IoStar size={28} /> Trustpilot
          </p>
          <span>
            They are the best when it comes to ease of use and definitely the
            best immediate support you receive compared to others.
          </span>
          <div>
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
          </div>
        </div>
        <div className="webhosting-part-5-section-1">
          <p>
            <IoStar size={28} /> Trustpilot
          </p>
          <span>
            They are the best when it comes to ease of use and definitely the
            best immediate support you receive compared to others.
          </span>
          <div>
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
          </div>
        </div>
        <div className="webhosting-part-5-section-1">
          <p>
            <IoStar size={28} /> Trustpilot
          </p>
          <span>
            They are the best when it comes to ease of use and definitely the
            best immediate support you receive compared to others.
          </span>
          <div>
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
            <IoStar size={25} />
          </div>
        </div>
      </div>
      <div className="webhosting-part-7">
        <p>Join over 3 million website owners</p>
        <button>Get started</button>
        <span>
          <TiTick size={25} />
          30-day money-back guarantee
        </span>
      </div>
      {/* <div className="webhosting-part-7">
        <p>Web hosting FAQs</p>
        <span>
          <TiTick size={25} />
          Discover answers to the most frequently asked questions about web
          server hosting.
        </span>
      </div> */}
      <ContactUs />
      <Footer />
    </div>
  );
};

export default WebHosting;
