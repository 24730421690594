import React from "react";

import { FaFacebook, FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { AiTwotoneCopyright } from "react-icons/ai";
import { Link } from "react-router-dom";
import img1 from "../../assets/ISO-9001-1-footer.svg";
import img2 from "../../assets/ISO-27001-2-1-footer.svg";
import img3 from "../../assets/Google_Cloud_Partner-1-1-footer.svg";
import img4 from "../../assets/Aws_partner-1-footer.svg";
import img5 from "../../assets/MS_solutions_partners_digital-1-footer.svg";
import img6 from "../../assets/MS_solutions_partners_data-1-footer.svg";
import img7 from "../../assets/IBM_Partner_Plus_silver_partner_mark_rev_white_RGB-1-footer.svg";
import img8 from "../../assets/Salesforce_Partner-1-footer.svg";
import img9 from "../../assets/Forbes-1-footer.svg";
import img10 from "../../assets/shopify-1-footer.svg";
import img11 from "../../assets/IAOP-1-footer.svg";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-part-1">
        <div className="footer-part-1-section-1">
          <p>
            Let<span>●</span>s develop software together!
          </p>
          <span>Conact us</span>
          <div className="footer-part-1-section-1-sub-1">
            <p>
              USA <span>+1 646 332 7131</span>
            </p>
            <p>
              Australia <span>+61 41 044 7364</span>
            </p>
            <p>
              Email <span>contact@legionssoft.com</span>
            </p>
          </div>
        </div>

        <div className="footer-part-1-section-2">
          <p>Services</p>
          <Link to="/MobileAppDevelopment">App Development</Link>
          <Link to="/web-development">Web Development</Link>
          <Link to="/digital-marketing">Digital Marketing</Link>
        </div>

        <div className="footer-part-1-section-3">
          <p>Odoo</p>
          <Link to="/OdooServices">Odoo</Link>
        </div>

        <div className="footer-part-1-section-5">
          <p>About us</p>
          <Link to="/about">How we work</Link>
        </div>
      </div>

      <div className="footer-part-4"></div>

      <div className="footer-part-5">
        <p>
          LEGIONSSOFT<span>●</span>
        </p>
        <div className="footer-part-4-section-1">
          <p>© 2007-2024 Legionsoft. All Rights Reserved.</p>
        </div>
        <div className="footer-part-4-section-2">
          <Link>
            <FaTwitter size={24} />
          </Link>
          <Link to="https://www.instagram.com/legionssoft/">
            <FaInstagram size={24} />
          </Link>
          <Link to="https://www.facebook.com/Legionssoft">
            <FaFacebook size={24} />
          </Link>
          <Link to="https://www.linkedin.com/company/legions-soft/?originalSubdomain=pk">
            <FaLinkedinIn size={24} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
